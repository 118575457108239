function Adgenda() {
  return (
    <div className="px-4">
      <div className="bg-gray-200 max-w-5xl mx-auto m-8 rounded-lg px-8 py-4">
        <div className="text-black text-md pt-4">
          <div className="font-semibold text-lg">
            IGNITE: The Aesthetic Show of 2025 Agenda
          </div>
          <div>8:15 -8:45 Registration</div>
          <div>9:00-9:45 Welcome and Introduction to Regenerative Medicine</div>
          <div>
            10:00-10:45 Aesthetic Treatments Utilized to Regenerate the Skin
          </div>
          <div>11:00-11:45 Neutraceuticals and Skin Care Products</div>
          <div>12:00-13:00 Lunch</div>
          <div>
            13:00-16:00 Breakout Sessions with Didactic and Immersive Hands on
            Training
          </div>
          <div>Breakout Session: Lip augmentation with Dr. Wise</div>
          <div>Breakout Session: Plasma Pen with Nurse Monika</div>
          <div>Breakout Session: Collagen pin with Aesthetic Cowboy</div>
          <div>Breakout Session: CO2 laser demo with Cameron Erlhich</div>
          <div>
            Breakout Session: Strategic Business Development, Maximizing the
            Bottom Line and Relationship Management with Amy A. Nealey, CEO
          </div>
        </div>
      </div>
    </div>
  );
}
export default Adgenda;
