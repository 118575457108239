import React, { useContext } from "react";
import { RegisterContext } from "./register_context";

function useRegister() {
  const { state, setState } = useContext(RegisterContext);

  const show = () => setState(true);
  const hide = () => setState(false);
  return {
    show,
    hide,
    state,
  };
}
export default useRegister;
