import React, { useState } from "react";

export const RegisterContext = React.createContext();

export const RegisterProvider = ({ children }) => {
  const [state, setState] = useState(false);
  return (
    <RegisterContext.Provider value={{ state, setState }}>
      {children}
    </RegisterContext.Provider>
  );
};
