import bgImage from "../resources/17525ab13bd27857520b6bcee7b299f1.jpg";
import { LOGO_CONTENT } from "../logo_content";
function Mission() {
  return (
    <div className="bg-inherit py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-4">
            <div className="relative overflow-hidden rounded-3xl bg-gray-900 px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
              <img
                alt=""
                src={bgImage}
                className="absolute inset-0 h-full w-full object-cover brightness-125 saturate-0"
              />
              <div className="absolute inset-0 bg-gray-900 mix-blend-multiply" />
              <div
                aria-hidden="true"
                className="absolute left-1/2 top-1/2 -ml-16 -translate-x-1/2 -translate-y-1/2 transform-gpu blur-3xl"
              >
                <div
                  style={{
                    clipPath:
                      "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                  }}
                  className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-40"
                />
              </div>
              <figure className="relative isolate h-80"></figure>
            </div>
          </div>
          <div>
            <div className="text-base/7 text-gray-200 lg:max-w-lg">
              <h1 className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">
                A MISSION TO EMPOWER, TRANSFORM AND ELEVATE!
              </h1>
              <div className="max-w-xl">
                <p className="mt-6">
                  Our mission is to bring practitioners of all disciplines
                  together cordially and synergistically with the sole purpose
                  to educate so that they may provide the most up to date,
                  efficient, effective modalities of aesthetic and regenerative
                  medicine theologies, practicalities and procedures to the
                  forefront in order to best serve their clients needs and
                  expectations.
                </p>
                <p className="mt-8">
                  Our purpose is to provide quality education, tools, procedures
                  and products that will benefit the practitioner and that
                  practitioners clientele to reduce human error, increase
                  patient satisfaction and to produce outstanding and optimal
                  results!
                </p>
                <p className="mt-8">Transform.Evolve.Grow</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Logo() {
  return (
    <div className="w-36">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 946 258">
        <path
          id="Selection"
          fill="white"
          stroke="white"
          strokeWidth="1"
          d={LOGO_CONTENT}
        />
      </svg>
    </div>
  );
}
export default Mission;
