import Hero from "./hero";
//import Testimonials from "./testimonials";
import RegisterCta from "./register_cta";
import KeyTopics from "./key_topics";
import Speakers from "./speakers";
import Logo from "./logo";
import Mission from "./mission";
import Format from "./format";
import MailList from "./mail_list";
import Stats from "./stats";
import Content from "./content";
import Partners from "./partners";
import Adgenda from "./adgenda";

function Home() {
  return (
    <div>
      <Hero />
      <Adgenda />
      <Stats />
      <Content />
      {/*update from notes in file*/}
      {/*<Objectives />*/}
      <Format />
      <Mission />
      <Logo />
      <MailList />
      <KeyTopics />
      <RegisterCta />
      <Partners />
      {/*<Testimonials />*/}
      <Speakers />
    </div>
  );
}
export default Home;
