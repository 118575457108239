import React, { useContext } from "react";
import { NotificationContext } from "./notification_context";

function useNotification() {
  const { state, setState } = useContext(NotificationContext);

  const success = (title, message) => {
    setState({
      show: true,
      message: message,
      title: title,
      type: "success",
    });
    setTimeout(close, 10000);
  };
  const error = (title, message) => {
    setState({
      show: true,
      message: message,
      title: title,
      type: "error",
    });
    setTimeout(close, 5000);
  };
  const close = () => {
    setState({
      show: false,
    });
  };
  return {
    state,
    success,
    error,
    close,
  };
}
export default useNotification;
