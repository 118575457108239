import Soloman from "../resources/Soloman.jpeg";

function Partners() {
  return (
    <div className="py-16">
      <div className="text-white text-6xl text-pretty font-bold max-w-7xl mx-auto px-12">
        Meet ISARM founders
      </div>
      <PartnerBio
        name="Dr. Soloman Jan"
        titles={solomanTitles}
        content={solomanContent}
        image={Soloman}
      />

      <PartnerBio
        name="Amy A. Nealey"
        titles={amyTitles}
        content={amyContent}
      />
    </div>
  );
}

function PartnerBio(props) {
  return (
    <div className="relative isolate overflow-hidden bg-inherit px-6 py-24 sm:py-16 lg:overflow-visible lg:px-0">
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-2 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:gap-x-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <h1 className="my-2 text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">
                {props.name}
              </h1>
              {props.titles.map((title, index) => (
                <p
                  key={`soloman-titles-${index}`}
                  className="text-sm text-gray-200"
                >
                  {title}
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-1 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          {props.image && (
            <img
              alt=""
              src={props.image}
              className="rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 mx-auto"
            />
          )}
        </div>
        <div className="lg:col-span-2 lg:col-start-2 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:gap-x-8 lg:pe-8">
          <div className="lg:pr-4">
            {props.content.map((c, index) => (
              <div
                key={`content-${index}`}
                className="max-w-xl text-gray-200 lg:max-w-lg pb-2"
              >
                {c}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const solomanTitles = [
  "FAAFP, CAQ-BE, CPT",
  "Associate Professor: MUSC- Dept of Medicine",
  "Geriatric Medicine",
  "Hospital Medicine",
  "Palliative Care and Hospice Medicine",
  "Aesthetic Medicine",
  "Functional Medicine",
  "Hormone and Peptide Medicine",
  "Certified Personal Trainer",
];
const amyTitles = ["PMP", "Co Founder, CEO"];
const solomanContent = [
  `Dr. Soloman Jan is a highly accomplished, board certified physician who
        encompasses 19 plus years experience in the world of medicine across
        various verticals and specialities. You will find that he has yielded
        tremendous success developing a strong foundation in both medical
        practice and business leadership. Dr. Solomon Jan has been recognized on
        an International Level as a Key Opinion Leader. His keen eye for detail,
        impeccable knowledge and natural talent of blending art and medicine
        truly place him in a league of his own. He has been instrumental in the
        strategic direction of several organizations, overseeing clinical
        protocols, training programs, and quality assurance measures. His
        leadership is focused on maintaining the highest standards of patient
        care, promoting ethical practices, and fostering a culture of continuous
        improvement and growth, all while committed to elevating the standards
        of safety, efficacy, and innovation within the aesthetic industry. Over
        the years, he has developed extensive expertise in the world of
        anti-aging, advanced injectable techniques, cutting edge laser
        therapies, regenerative medicine and non-surgical aesthetic enhancement
        procedures. Dr. Soloman Jan's passion for advancing the field of
        aesthetics through research, education, and technology radiate through
        all that he sets out to accomplish. His entrepreneurial drive has led
        him to launch and develop highly successful businesses and medspas
        yielding turn key operations that have later sold for substantial
        amounts. In addition to his clinical expertise, Dr. Solomon Jan is a
        trusted partner and co-founder of the International Society of Aesthetic
        Regenerative Medicine.`,
];
const amyContent = [
  `A dynamic and results-driven Chief Executive Officer, Amy specializes in Aesthetic Regenerative Medicine.  Encompassing 16 plus years experience she has been continuously recognized as a visionary leader with a proven track record of successfully building, scaling, and launching innovative businesses within the healthcare and aesthetics industries. With a deep understanding of both the scientific advancements and market dynamics in regenerative medicine, Amy has consistently led organizations to new heights by combining cutting-edge technology with a patient-centric approach.`,
  `With a passion for leveraging regenerative therapies and advanced aesthetic treatments,Amy has established and led multiple ventures, successfully navigating the complexities of funding, launching from ground up  to market entry. Under her leadership, companies have secured substantial investments, formed strategic partnerships, and launched breakthrough products and services that have transformed the landscape of beauty, wellness, and medical aesthetics.`,

  `Amy is known for their ability to build high-performance teams, inspire innovation, and implement operational strategies that deliver strong financial performance.She has also developed a reputation for driving the commercialization of state-of-the-art treatments, focusing on patient safety, efficacy, and long-term outcomes. As a trusted thought leader in cosmetic regenerative medicine, Amy remains at the forefront of industry trends, ensuring organizations continuously meet the evolving demands of the market.`,

  `With an entrepreneurial spirit and a passion for transforming the aesthetic and healthcare sectors you will find that Amy continues to shape the future of regenerative medicine, combining scientific breakthroughs with business acumen to create sustainable, profitable, and impactful companies.`,
];
function backup(props) {
  return (
    <div className="relative isolate overflow-hidden bg-inherit px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <h1 className="my-2 text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">
                {props.name}
              </h1>
              {props.titles.map((title, index) => (
                <p
                  key={`soloman-titles-${index}`}
                  className="text-sm text-gray-200"
                >
                  {title}
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            alt=""
            src={props.image}
            className="rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 mx-auto"
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-gray-200 lg:max-w-lg">
              {props.content}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
