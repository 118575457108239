import thomas from "../resources/thomas.jpg";
import aliena from "../resources/aliena.jpg";

function Speakers() {
  return (
    <div className="text-white text-5xl max-w-5xl mx-auto font-bold">
      <div className="px-8 lg:px-32">Speakers</div>
      <Monika />
      <div className="px-12 lg:px-32 text-2xl lg:text-5xl">
        More Keynote speakers and educators to be revealed soon!
      </div>
    </div>
  );
}
function Monika() {
  return (
    <div className="text-sm font-normal py-8 px-8 lg:py-24 lg:px-16">
      <div className="text-xl font-semibold">Monika Lenki,</div>
      <div className="text-xl font-semibold">RN</div>
      <div className="flex flex-col gap-2">
        <div>
          Monika's story is one of resilience, exploration, and an unwavering
          commitment to her craft. Born and raised in Hungary, Monika immigrated
          to the United States in her early 20s, driven by a spirit of adventure
          and the desire for a new chapter. Her career began in the dynamic
          world of the automotive industry, where she traveled extensively
          across the U.S as brand ambassador for several auto manufacturers,
          gaining over a decade of diverse experience and honing her
          adaptability and work ethic.
        </div>

        <div>
          Later, eager for a change and driven by a desire to make a meaningful
          difference, Monika pursued a career in nursing, by becoming a
          registered nurse. While her time in hospital settings allowed her to
          develop valuable skills, she soon realized that her true passion lay
          elsewhere.
        </div>
        <div>
          About six years ago, Monika found her calling in the field of
          aesthetics. Working with several medical spas in the Myrtle Beach
          area, she discovered an industry that combined her precision,
          creativity, and deep care for others. She takes immense pride in her
          work, consistently striving for excellence and earning the trust of
          her clients.
        </div>
        <div>
          Now, Monika is dedicated not only to her own practice but also to
          sharing her knowledge with those interested in pursuing careers in
          aesthetics. Her enthusiasm and expertise inspire others to follow
          their dreams with confidence.
        </div>
        <div>
          Monika lives in Myrtle Beach, where she enjoys the coastal lifestyle
          and cherishes her role as the proud mother of two young adult sons.
          Her journey continues to be a testament to the power of reinvention
          and the pursuit of one's true passion.
        </div>
      </div>
    </div>
  );
}
function Backup() {
  return (
    <div className="bg-inherit py-24 md:py-32">
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-20 px-6 lg:px-8 xl:grid-cols-5">
        <div className="max-w-2xl xl:col-span-2">
          <h2 className="text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl">
            Meet the guest speakers
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-200">
            We have brought in the best of the best, so that you can learn from
            the best of the best. Find out more about our amazing guests
          </p>
        </div>
        <ul className="-mt-12 space-y-12 divide-y divide-gray-200 xl:col-span-3">
          {people.map((person) => (
            <li
              key={person.name}
              className="flex flex-col gap-10 pt-12 sm:flex-row"
            >
              <img
                alt=""
                src={person.imageUrl}
                className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover"
              />
              <div className="max-w-xl flex-auto">
                <h3 className="text-lg font-semibold leading-8 tracking-tight text-white">
                  {person.name}
                </h3>
                <p className="text-base leading-7 text-gray-200">
                  {person.role}
                </p>
                <p className="mt-6 text-base leading-7 text-gray-200">
                  {person.bio}
                </p>
                <ul className="mt-6 flex gap-x-6">
                  <li>
                    <a
                      href={person.xUrl}
                      className="text-gray-400 hover:text-gray-200"
                    >
                      <span className="sr-only">X</span>
                      <svg
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        className="h-5 w-5"
                      >
                        <path d="M11.4678 8.77491L17.2961 2H15.915L10.8543 7.88256L6.81232 2H2.15039L8.26263 10.8955L2.15039 18H3.53159L8.87581 11.7878L13.1444 18H17.8063L11.4675 8.77491H11.4678ZM9.57608 10.9738L8.95678 10.0881L4.02925 3.03974H6.15068L10.1273 8.72795L10.7466 9.61374L15.9156 17.0075H13.7942L9.57608 10.9742V10.9738Z" />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      href={person.linkedinUrl}
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <span className="sr-only">LinkedIn</span>
                      <svg
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        className="h-5 w-5"
                      >
                        <path
                          d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                          clipRule="evenodd"
                          fillRule="evenodd"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

const people = [
  {
    name: "Thomas McAndrew",
    role: "Web dev",
    imageUrl: thomas,
    bio: "Best developer in the south, Thomas hails from the frosty tundra of Dubuque Iowa, after fleeing for his own safetey, he makes websites for only the best skin care providers.",
    xUrl: "#",
    linkedinUrl: "#",
  },
  {
    name: "Aliena McAndrew",
    role: "Emotional support cat",
    imageUrl: aliena,
    bio: "Emotional support cat extrodinare, Aliena will be meowing the tune of Wolfgang Amadeus Motzarts Lacrimosa.",
    xUrl: "#",
    linkedinUrl: "#",
  },
  // More people...
];
export default Speakers;
