import { Label } from "../components/label";
import { Input } from "../components/input";
import { Select } from "../components/select";
import { useMask } from "@react-input/mask";
import { LabelInputContainer } from "../components/label_input_container";
function PracticeInformation(props) {
  const inputRef = useMask({
    mask: "(___) ___-____",
    replacement: { _: /\d/ },
  });
  return (
    <>
      <div className="">
        <LabelInputContainer className="mb-4">
          <Label htmlFor="ownOwnPractice">Do you own your own practice?</Label>
          <Select
            name="own_practice"
            onChange={props.handleChange}
            value={props.state.own_practice}
            id="ownOwnPractice"
            required
          >
            <option disabled selected value="">
              -- select an option --
            </option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </Select>
        </LabelInputContainer>
        <div className="lg:flex lg:gap-2">
          <LabelInputContainer className="mb-4">
            <Label htmlFor="practiceName">Practice Name</Label>
            <Input
              name="practice_name"
              onChange={props.handleChange}
              value={props.state.practice_name}
              id="practiceName"
              type="text"
            />
          </LabelInputContainer>
          <LabelInputContainer className="mb-4">
            <Label htmlFor="practiceNumber">Practice Number</Label>
            <Input
              name="practice_number"
              onChange={props.handleChange}
              value={props.state.practice_number}
              ref={inputRef}
              id="practiceNumber"
              type="phone_number"
            />
          </LabelInputContainer>
        </div>
        <LabelInputContainer className="mb-4">
          <Label htmlFor="practiceAddress">Practice Address</Label>
          <Input
            name="practice_address"
            onChange={props.handleChange}
            value={props.state.practice_address}
            id="practiceAddress"
            autoComplete="practice street-address"
            type="text"
          />
        </LabelInputContainer>
      </div>
    </>
  );
}
export default PracticeInformation;
