const stats = [
  { id: 1, name: "", value: "1 DAY" },
  {
    id: 2,
    name: "",
    value: "8 EDUCATORS",
  },
];

function Stats() {
  return (
    <div className="bg-inherit sm:py-32">
      <div className="mx-auto max-w-3xl px-6 lg:px-8">
        <dl className="text-center lg:flex">
          {stats.map((stat) => (
            <div
              key={stat.id}
              className="mx-auto flex max-w-xs flex-col pb-16 lg:pb-0"
            >
              <dt className="text-base/7 text-white">{stat.name}</dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-gray-200 sm:text-5xl">
                {stat.value}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
export default Stats;
