import useRegister from "../register/use_register";

function RegisterCta() {
  const register = useRegister();
  return (
    <div className="bg-inherit">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-balance text-4xl font-semibold tracking-tight text-white sm:text-5xl">
            Ready to take your practice to the next level?
          </h2>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <button
              onClick={register.show}
              className="rounded-md bg-[#2020d4] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#4242ed] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Register today
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterCta;
