import axios from "axios";
const API_ROUTE = `${process.env.REACT_APP_API_URL}`;

export async function joinMailingList(email) {
  const response = await axios.post(`${API_ROUTE}/mail_list`, {
    email: email,
  });
  return response.data;
}
export async function register(user) {
  try {
    const response = await axios.post(`${API_ROUTE}/register`, user);
    return response.data;
  } catch (err) {
    return {
      stat: "ERROR",
      message:
        "Failed to process card, double check your information and try again",
      error: err,
    };
  }
}
