import { useState } from "react";
import useNotification from "../notification/use_notification";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import useRegister from "./use_register";
import React from "react";
import ContactInformation from "./contact_information";
import PracticeInformation from "./practice_information";
import MedicalInformation from "./medical_information";
import PaymentInformation from "./payment_information";
import TermsAndConditions from "./terms_and_conditions";
import SelectTier from "./select_tier";
import { register } from "../api";

function RegistrationForm({ hide }) {
  const notify = useNotification();
  const [page, setPage] = useState("form");
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    primary_degree: "",
    email: "",
    phone_number: "",
    years_medicine: 0,
    hear_about: "",
    refferal: "",
    insta: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
    own_practice: null,
    practice_name: "",
    practice_number: "",
    practice_address: "",
    medical_license_number: "",
    medical_exp: "",
    medical_specialty: "",
    selected_tier: "MEDICAL",

    //fields for backend
    registration_id: 0,
    payment_confirmed: false,
  });
  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const handleTokenResponse = async (token, verifiedBuyer) => {
    console.log(token);
    handleSubmit({
      registration: {
        ...state,
        years_medicine: +state.years_medicine,
        created_date: new Date().toISOString().split(".")[0],
        own_practice: state.own_practice === "true" ? true : false,
      },
      source_id: token.token,
    });
  };

  const handleSubmit = async (order) => {
    console.log("Form submitted");
    const response = await register(order);
    console.log(response);
    if (response?.stat === "ERROR") {
      notify.error("Error!", response.message);
    } else {
      notify.success(
        "Success!",
        "Thank you for registering for ISARM, you will recieve a confirmation email in your inbox.",
      );
      hide();
    }
  };
  const submitInformation = () => {
    setPage("checkout");
  };
  return (
    <div className="w-full mx-auto p-4 md:p-8 bg-white dark:bg-black">
      <h2 className="font-bold text-xl text-neutral-800 dark:text-neutral-200">
        ISARM 2025
      </h2>
      <p className="text-neutral-600 text-sm max-w-sm mt-2 dark:text-neutral-300">
        Register today to take advantage of early bird savings
      </p>
      {page === "form" && (
        <form className="my-8" onSubmit={submitInformation}>
          <SectionOutline name="Contact Information">
            <ContactInformation state={state} handleChange={handleChange} />
          </SectionOutline>
          <SectionOutline name="Practice Information">
            <PracticeInformation state={state} handleChange={handleChange} />
          </SectionOutline>
          <SectionOutline name="Medical Information">
            <MedicalInformation state={state} handleChange={handleChange} />
          </SectionOutline>
          <SectionOutline name="Terms and Conditions">
            <TermsAndConditions />
          </SectionOutline>
          <button
            className="bg-gradient-to-br relative group/btn from-black dark:from-zinc-900 dark:to-zinc-900 to-neutral-600 block dark:bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset] dark:shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
            type="submit"
          >
            Register &rarr;
            <BottomGradient />
          </button>
        </form>
      )}
      {page === "checkout" && (
        <div>
          <div className="mt-4">
            <SelectTier state={state} handleChange={handleChange} />
          </div>
          <SectionOutline name="Payment Information">
            <PaymentInformation handleTokenResponse={handleTokenResponse} />
          </SectionOutline>
          <button
            onClick={() => setPage("form")}
            className="shadow-md p-4 rounded-md border-2 hover:bg-gray-200"
          >
            &larr; Back
          </button>
        </div>
      )}
    </div>
  );
}

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};
function RegisterDialog() {
  const openState = useRegister();
  return (
    <Dialog
      open={openState.state}
      onClose={() => {
        //Locking auto close
      }}
      className="relative z-30"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm lg:max-w-5xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <Content hide={openState.hide} />
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
function Content({ hide }) {
  return (
    <div className="overflow-auto xs:h-80 lg:h-fit">
      <div className="flex justify-end w-100">
        <button
          onClick={hide}
          className="rounded-full p-2 text-black shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
        >
          <XMarkIcon className="w-5 h-5" />
        </button>
      </div>
      <RegistrationForm hide={hide} />
    </div>
  );
}
function SectionOutline({ children, name }) {
  return (
    <div className="border rounded-md shadow-md my-4 p-2 divide-y">
      <div className="text-xl pb-2 m-2 font-semibold">{name}</div>
      <div className="pt-4 m-2">{children}</div>
    </div>
  );
}
export default RegisterDialog;
