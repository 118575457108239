import React, { useState } from "react";

export const NotificationContext = React.createContext();

export const NotificationProvider = ({ children }) => {
  const [state, setState] = useState({
    show: false,
    message: "",
    title: "",
    type: "",
  });

  return (
    <NotificationContext.Provider value={{ state, setState }}>
      {children}
    </NotificationContext.Provider>
  );
};
