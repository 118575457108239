import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RegisterProvider } from "./register/register_context";
import { NotificationProvider } from "./notification/notification_context";
import Home from "./home/home";
import Navigation from "./navigation/navigation";
import ErrorPage from "./error_page/error_page";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigation />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
    ],
  },
]);

function App() {
  return (
    <RegisterProvider>
      <NotificationProvider>
        <RouterProvider router={router} />
      </NotificationProvider>
    </RegisterProvider>
  );
}

export default App;
