import { Label } from "../components/label";
import { Input } from "../components/input";
import { Select } from "../components/select";
import { useMask } from "@react-input/mask";
import { LabelInputContainer } from "../components/label_input_container";
function ContactInformation(props) {
  const inputRef = useMask({
    mask: "(___) ___-____",
    replacement: { _: /\d/ },
  });
  return (
    <>
      <div className="lg:flex lg:gap-2">
        <LabelInputContainer className="mb-4">
          <Label htmlFor="firstname">First Name</Label>
          <Input
            name="first_name"
            onChange={props.handleChange}
            value={props.state.first_name}
            id="firstname"
            type="text"
            autoComplete="given-name"
            required
          />
        </LabelInputContainer>
        <LabelInputContainer className="mb-4">
          <Label htmlFor="lastname">Last Name</Label>
          <Input
            name="last_name"
            onChange={props.handleChange}
            value={props.state.last_name}
            id="lastname"
            type="text"
            autoComplete="family-name"
            required
          />
        </LabelInputContainer>
      </div>
      <div className="lg:flex lg:gap-2 lg:flex-row-reverse">
        <div className="flex-1">
          <LabelInputContainer className="mb-4">
            <Label htmlFor="email">Email Address</Label>
            <Input
              name="email"
              onChange={props.handleChange}
              value={props.state.email}
              id="email"
              type="email"
              autoComplete="email"
              required
            />
          </LabelInputContainer>
          <LabelInputContainer className="mb-4">
            <Label htmlFor="phone_number">Phone Number</Label>
            <Input
              name="phone_number"
              onChange={props.handleChange}
              value={props.state.phone_number}
              ref={inputRef}
              id="phone_number"
              type="phone_number"
              autoComplete="tel"
            />

            <div className="text-xs">
              By entering your mobile number above, you authorize AES, or it’s
              designated vendor, to send text messages (SMS and MMS). Text
              messages are sent using autodialed technology to the wireless
              number you use to subscribe. Text STOP to cancel, HELP for help.
              Message and data rates may apply.
            </div>
          </LabelInputContainer>
        </div>
        <div className="flex-1">
          <LabelInputContainer className="mb-4">
            <Label htmlFor="degree">Primary Degree</Label>
            <Select
              name="primary_degree"
              onChange={props.handleChange}
              value={props.state.primary_degree}
              id="degree"
            >
              <option disabled selected value="">
                -- select an option --
              </option>

              <option value="APRN">APRN</option>
              <option value="BSN">BSN</option>
              <option value="DO">DO</option>
              <option value="MD">MD</option>
              <option value="MSN">MSN</option>
              <option value="PA">PA</option>
              <option value="RN">RN</option>
              <option value="NP">NP</option>
              <option value="Other">Other</option>
            </Select>
          </LabelInputContainer>

          <LabelInputContainer className="mb-4">
            <Label htmlFor="practice_years">
              How many years have you been practicing aesthetic medicine?
            </Label>
            <Select
              name="years_medicine"
              onChange={props.handleChange}
              value={props.state.years_medicine}
              id="practice_years"
            >
              {[...Array(31).keys()].map((years) => (
                <option value={years} key={years}>
                  {years}
                </option>
              ))}
            </Select>
          </LabelInputContainer>
        </div>
      </div>
      <div className="lg:flex lg:gap-2 lg:items-end">
        <LabelInputContainer className="mb-4">
          <Label htmlFor="hear_from">How did you hear about ISARM?</Label>
          <Select
            name="hear_about"
            onChange={props.handleChange}
            value={props.state.hear_about}
            id="hear_from"
          >
            <option disabled selected value="">
              -- select an option --
            </option>
            <option value="Attended Previously">Attended Previously</option>
            <option value="Family or Friend">Family or Friend</option>
            <option value="AES E-blast">AES E-blast</option>
            <option value="Facebook">Facebook</option>
            <option value="Twitter">Twitter</option>
            <option value="Instagram">Instagram</option>
            <option value="LinkedIn">LinkedIn</option>
            <option value="Website/Search Engine">Website/Search Engine</option>
            <option value="Online Advertisement">Online Advertisement</option>
            <option value="Other">Other</option>
          </Select>
        </LabelInputContainer>

        <LabelInputContainer className="mb-4">
          <Label htmlFor="refferal">
            If you were reffered by a company or individual, please include
            their name below.
          </Label>
          <Input
            name="refferal"
            onChange={props.handleChange}
            value={props.state.refferal}
            id="refferal"
          />
        </LabelInputContainer>
      </div>
      <LabelInputContainer className="mb-4 lg:w-1/2">
        <Label htmlFor="insta">Instagram Handle/Username</Label>
        <Input
          name="insta"
          onChange={props.handleChange}
          value={props.state.insta}
          id="insta"
          type="text"
        />
      </LabelInputContainer>

      <div className="border-t mb-4" />

      <div className="lg:flex lg:gap-2">
        <LabelInputContainer className="mb-4">
          <Label htmlFor="address_1">Address 1</Label>
          <Input
            name="address_1"
            onChange={props.handleChange}
            value={props.state.address_1}
            id="address_1"
            autoComplete="address-line1"
          />
        </LabelInputContainer>

        <LabelInputContainer className="mb-4">
          <Label htmlFor="address_2">Address 2</Label>
          <Input
            name="address_2"
            onChange={props.handleChange}
            value={props.state.address_2}
            id="address_2"
            autoComplete="address-line2"
          />
        </LabelInputContainer>
      </div>
      <div className="lg:flex lg:gap-2">
        <LabelInputContainer className="mb-4">
          <Label htmlFor="city">City</Label>
          <Input
            name="city"
            onChange={props.handleChange}
            value={props.state.city}
            id="city"
            autoComplete="address-level2"
          />
        </LabelInputContainer>

        <LabelInputContainer className="mb-4">
          <Label htmlFor="state">State</Label>
          <Input
            name="state"
            onChange={props.handleChange}
            value={props.state.state}
            id="state"
            autoComplete="address-level1"
          />
        </LabelInputContainer>
      </div>

      <div className="lg:flex lg:flex-row-reverse lg:gap-2">
        <LabelInputContainer className="mb-4">
          <Label htmlFor="zip_code">Zip Code</Label>
          <Input
            name="zip_code"
            onChange={props.handleChange}
            value={props.state.zip_code}
            id="zip_code"
            autoComplete="postal-code"
          />
        </LabelInputContainer>
        <LabelInputContainer className="mb-4">
          <Label htmlFor="country">Country</Label>
          <Input
            name="country"
            onChange={props.handleChange}
            value={props.state.country}
            id="country"
            autoComplete="country-name"
          />
        </LabelInputContainer>
      </div>
    </>
  );
}
export default ContactInformation;
