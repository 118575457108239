export const LOGO_CONTENT = `M 14.00,12.74
           C 19.34,11.45 35.67,11.89 42.00,12.00
             46.77,12.09 49.44,12.79 52.28,17.02
             56.42,23.19 55.00,43.87 55.00,52.00
             55.00,52.00 55.00,76.00 55.00,76.00
             54.99,81.30 54.91,88.38 50.61,92.20
             47.16,95.27 43.29,94.99 39.00,95.00
             39.00,95.00 22.00,95.00 22.00,95.00
             12.04,94.95 7.15,92.68 7.00,82.00
             7.00,82.00 7.00,33.00 7.00,33.00
             7.00,24.22 4.92,17.54 14.00,12.74 Z
           M 229.00,244.00
           C 224.55,243.95 221.11,246.24 217.00,247.77
             208.20,251.06 194.37,254.25 185.00,253.98
             181.81,253.90 178.89,253.58 176.39,251.35
             173.25,248.55 173.06,244.89 173.00,241.00
             173.00,241.00 173.00,221.00 173.00,221.00
             173.08,216.25 173.83,212.68 178.09,209.85
             181.70,207.45 189.52,206.83 194.00,205.76
             204.18,203.35 214.10,197.10 215.70,186.00
             216.12,183.09 216.02,179.69 214.69,177.01
             212.58,172.80 207.98,170.34 204.00,168.18
             193.19,162.29 183.65,159.92 172.00,156.71
             147.51,149.95 122.38,146.33 100.00,133.42
             86.86,125.84 75.23,114.86 70.77,100.00
             69.61,96.12 68.34,86.03 68.34,82.00
             68.34,54.63 81.92,33.86 106.00,21.17
             114.81,16.53 129.03,12.42 139.00,12.04
             143.66,11.86 148.05,12.34 151.11,16.33
             154.78,21.10 154.01,32.06 154.00,38.00
             153.99,43.38 153.79,51.50 149.61,55.43
             146.48,58.38 141.94,58.64 138.00,59.75
             128.76,62.34 117.76,68.59 116.31,79.00
             115.89,81.97 116.20,84.37 117.89,86.91
             121.01,91.58 128.82,95.57 134.00,97.57
             145.64,102.06 160.70,105.34 173.00,108.23
             204.65,115.68 251.26,127.94 261.96,163.00
             264.67,171.86 264.53,178.99 264.03,188.00
             263.55,196.91 262.20,202.66 259.00,211.00
             259.00,211.00 266.00,205.74 266.00,205.74
             266.00,205.74 279.00,205.00 279.00,205.00
             279.00,205.00 304.00,205.00 304.00,205.00
             304.00,205.00 419.00,205.00 419.00,205.00
             419.00,205.00 405.60,171.00 405.60,171.00
             405.60,171.00 377.99,105.00 377.99,105.00
             377.99,105.00 368.00,81.00 368.00,81.00
             368.00,81.00 343.00,139.00 343.00,139.00
             343.00,139.00 328.80,173.00 328.80,173.00
             326.84,177.84 324.68,184.71 319.91,187.31
             314.92,190.04 297.47,189.01 291.00,189.00
             288.50,189.00 285.38,189.10 283.00,188.35
             267.52,183.43 279.72,163.04 283.58,154.00
             283.58,154.00 328.28,49.00 328.28,49.00
             328.28,49.00 337.72,27.00 337.72,27.00
             339.58,22.69 341.35,17.45 345.10,14.43
             348.02,12.08 350.46,12.05 354.00,12.00
             354.00,12.00 380.00,12.00 380.00,12.00
             384.05,12.01 387.38,11.71 390.67,14.57
             393.91,17.38 397.67,26.76 399.40,31.00
             399.40,31.00 413.40,64.00 413.40,64.00
             413.40,64.00 459.58,173.00 459.58,173.00
             459.58,173.00 485.00,231.00 485.00,231.00
             485.00,231.00 485.00,138.00 485.00,138.00
             485.02,126.38 488.00,122.02 500.00,122.00
             500.00,122.00 594.00,122.00 594.00,122.00
             613.68,121.97 638.32,114.28 636.91,90.00
             635.47,65.21 614.25,58.03 593.00,58.00
             593.00,58.00 501.00,58.00 501.00,58.00
             496.39,57.99 491.93,58.21 488.42,54.61
             485.34,51.45 485.05,47.14 485.00,43.00
             484.90,34.34 482.49,16.83 492.02,12.85
             494.41,11.86 497.44,12.00 500.00,12.00
             500.00,12.00 579.00,12.00 579.00,12.00
             579.00,12.00 608.00,12.00 608.00,12.00
             631.66,12.04 656.67,23.80 670.79,43.00
             689.54,68.73 690.22,111.44 670.79,137.00
             664.35,145.61 659.55,149.38 650.00,154.24
             647.87,155.33 641.49,157.79 641.50,160.53
             641.50,161.99 643.52,164.77 644.34,166.00
             644.34,166.00 652.19,178.00 652.19,178.00
             652.19,178.00 683.42,226.00 683.42,226.00
             685.40,229.18 689.03,234.34 689.52,238.00
             690.17,242.83 686.52,248.71 682.00,250.40
             680.09,251.11 677.06,251.00 675.00,251.00
             675.00,251.00 650.00,251.00 650.00,251.00
             647.58,250.96 645.25,250.99 643.04,249.83
             638.83,247.61 631.75,235.50 629.00,231.00
             629.00,231.00 600.05,185.00 600.05,185.00
             597.71,181.31 592.15,171.08 588.82,169.17
             586.37,167.78 582.76,168.00 580.00,168.00
             580.00,168.00 548.00,168.00 548.00,168.00
             545.10,168.00 537.53,167.55 535.31,169.02
             532.55,170.86 533.01,175.09 533.00,178.00
             533.00,178.00 533.00,235.00 533.00,235.00
             533.00,237.52 533.11,240.60 532.35,243.00
             528.92,253.88 515.05,251.00 506.00,251.00
             498.11,251.00 491.61,252.67 487.00,245.00
             487.00,245.00 485.00,245.00 485.00,245.00
             481.94,251.27 478.55,251.92 472.00,252.00
             472.00,252.00 431.00,252.00 431.00,252.00
             431.00,252.00 265.00,252.00 265.00,252.00
             262.48,252.00 259.40,252.11 257.00,251.35
             254.08,250.43 251.67,248.62 250.14,245.96
             244.31,235.83 256.73,225.68 256.00,215.00
             253.60,223.15 246.64,230.21 240.00,235.25
             237.04,237.50 230.08,240.78 229.00,244.00 Z
           M 183.00,12.56
           C 188.42,12.35 193.79,13.74 199.00,15.15
             218.72,20.48 235.22,29.98 247.11,47.01
             253.65,56.38 256.58,65.82 258.13,77.00
             258.98,83.16 259.65,89.04 253.89,93.30
             248.51,97.29 232.04,96.09 225.00,96.00
             222.99,95.97 220.94,95.96 219.00,95.35
             207.55,91.75 212.15,79.98 203.79,71.17
             199.99,67.17 194.15,63.64 189.00,61.68
             184.05,59.80 177.80,58.61 174.74,53.96
             172.73,50.91 173.01,47.47 173.00,44.00
             172.99,37.32 171.56,21.12 175.65,16.22
             177.79,13.66 179.94,13.21 183.00,12.56 Z
           M 883.00,97.00
           C 878.73,100.95 869.42,113.72 865.35,119.00
             865.35,119.00 837.13,155.00 837.13,155.00
             831.96,161.82 824.41,175.12 815.00,174.94
             807.66,174.80 805.11,170.30 801.13,165.00
             801.13,165.00 783.79,142.00 783.79,142.00
             783.79,142.00 727.12,67.00 727.12,67.00
             727.12,67.00 708.27,42.00 708.27,42.00
             705.65,38.47 700.76,32.71 699.01,29.00
             696.15,22.93 698.51,15.25 705.00,12.74
             707.25,11.87 710.57,12.00 713.00,12.00
             713.00,12.00 735.00,12.00 735.00,12.00
             746.11,12.06 747.81,16.78 754.21,25.00
             754.21,25.00 776.00,54.00 776.00,54.00
             776.00,54.00 788.27,70.00 788.27,70.00
             788.27,70.00 816.00,106.00 816.00,106.00
             818.71,99.61 823.98,93.61 828.12,88.00
             828.12,88.00 851.71,56.00 851.71,56.00
             851.71,56.00 873.13,27.00 873.13,27.00
             880.08,17.71 881.58,12.06 894.00,12.00
             894.00,12.00 918.00,12.00 918.00,12.00
             922.85,12.07 926.36,12.64 929.26,17.04
             931.27,20.08 930.99,23.53 931.00,27.00
             931.00,27.00 931.00,192.00 931.00,192.00
             931.00,192.00 931.00,228.00 931.00,228.00
             931.00,232.19 931.59,241.56 929.83,244.98
             926.75,250.95 921.83,250.99 916.00,251.00
             916.00,251.00 897.00,251.00 897.00,251.00
             886.52,250.86 883.13,246.12 883.00,236.00
             883.00,236.00 883.00,167.00 883.00,167.00
             883.00,167.00 883.00,97.00 883.00,97.00 Z
           M 708.00,44.00
           C 708.00,44.00 709.00,44.00 709.00,44.00
             709.00,44.00 708.00,45.00 708.00,45.00
             708.00,45.00 708.00,44.00 708.00,44.00 Z
           M 118.00,83.00
           C 118.00,83.00 119.00,83.00 119.00,83.00
             119.00,83.00 118.00,84.00 118.00,84.00
             118.00,84.00 118.00,83.00 118.00,83.00 Z
           M 16.00,107.56
           C 21.37,106.62 33.07,106.93 39.00,107.00
             52.01,107.16 54.85,112.90 54.90,125.00
             54.90,125.00 54.90,212.00 54.90,212.00
             54.90,212.00 54.90,240.00 54.90,240.00
             54.10,244.05 52.56,248.40 48.82,250.55
             45.91,252.21 42.24,251.99 39.00,252.00
             39.00,252.00 21.00,252.00 21.00,252.00
             9.69,251.86 7.02,247.65 7.00,237.00
             7.00,237.00 7.00,140.00 7.00,140.00
             7.00,135.02 6.34,117.55 7.93,114.04
             9.62,110.32 12.38,108.85 16.00,107.56 Z
           M 69.02,165.74
           C 74.49,164.41 91.46,164.91 98.00,165.00
             102.37,165.06 105.46,165.29 108.30,169.11
             110.84,172.51 110.86,177.04 112.04,181.00
             113.76,186.75 116.48,191.68 121.04,195.68
             125.99,200.01 131.83,202.59 138.00,204.65
             142.25,206.06 147.37,206.68 150.43,210.22
             154.99,215.49 154.01,227.26 154.00,234.00
             153.99,237.54 154.02,240.52 153.15,244.00
             149.28,259.54 130.48,252.15 120.00,249.14
             94.44,241.79 75.70,225.07 66.59,200.00
             64.28,193.65 60.44,178.25 63.01,172.00
             64.42,168.56 65.91,167.45 69.02,165.74 Z
           M 706.00,168.57
           C 711.37,167.60 724.92,167.92 731.00,168.00
             735.52,168.06 739.41,168.54 742.57,172.21
             745.34,175.42 744.99,179.06 745.00,183.00
             745.00,183.00 746.00,199.00 746.00,199.00
             746.00,199.00 746.00,220.00 746.00,220.00
             746.00,220.00 745.32,231.00 745.32,231.00
             745.79,237.73 746.07,246.32 738.98,249.98
             736.63,251.19 733.59,250.99 731.00,251.00
             731.00,251.00 711.00,251.00 711.00,251.00
             699.01,250.92 697.05,243.41 697.00,233.00
             697.00,233.00 697.00,185.00 697.00,185.00
             697.09,177.25 698.14,171.68 706.00,168.57 Z`;
