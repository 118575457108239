import { Outlet } from "react-router-dom";
import Footer from "../footer/footer";
import Banner from "../banner/banner";
import Notification from "../notification/notification";
import { LOGO_CONTENT } from "../logo_content";
import RegisterDialog from "../register/register_dialog";
function Navigation() {
  return (
    <div className="bg-black">
      <RegisterDialog />
      <Banner />
      <header>
        <nav
          aria-label="Global"
          className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        >
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <Logo />
            </a>
          </div>
        </nav>
      </header>
      <main className="">
        <Outlet />
      </main>
      <Footer />
      <Notification />
    </div>
  );
}

function Logo() {
  return (
    <div className="w-24">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 946 258">
        <defs>
          <linearGradient id="Gradient1" x1="0" x2="0" y1="0" y2="1">
            <stop stopColor="#2020d4" offset="0%" />
            <stop stopColor="#550b9e" offset="100%" />
          </linearGradient>
        </defs>
        <path
          id="Selection"
          fill="url(#Gradient1)"
          stroke="black"
          strokeWidth="1"
          d={LOGO_CONTENT}
        />
      </svg>
    </div>
  );
}
export default Navigation;
