function TermsAndConditions() {
  return (
    <div>
      <div className="font-semibold text-lg pb-2">Registration Information</div>
      <div>
        By registering for the ISARM Symposium, the attendee understands and
        agrees that attendee's full contact information will be shared with
        other attendees and exhibitors
      </div>
      <div className="font-semibold text-lg py-2">Code of Conduct</div>
      <div></div>The ISARM Symposium expects all meeting attendees and vendors
      to maintain high standards of professional conduct and uphold the policies
      and procedures set forth for the meeting. To the degree that an attendee
      or vendor, individually or collectively, purposefully and fraudulently
      circumvents the ISARM rules, regulations and ethical standards, the ISARM
      vies such conduct as serious violation that will jeopardize attendance at
      the meeting and could jeopardize attendance at future ISARM meetings. The
      ISARM prohibits harassment by a client, an attendee, vendor, or others who
      may interact with staff during their course of business. The ISARM
      reserves the right to refuse entry, remove or revoke the registration of
      any individual or vendor, without refund, if such refusal is consistent
      with ISARM policy.
    </div>
  );
}
export default TermsAndConditions;
