import { useNavigate } from "react-router-dom";
import useRegister from "../register/use_register";
import img1 from "../resources/17525ab13bd27857520b6bcee7b299f1.jpg";
import img2 from "../resources/9e5a0c6787b6bc6fbac3b60a63d288f4.jpg";
import img3 from "../resources/5f9567f533ca1b8d38ff2cbf86d848cb.jpg";
import img4 from "../resources/db4bdaf4b49af391650b7df743ef935a.jpg";
import img5 from "../resources/dc58ffdd3f90cf51f82057ed9d19370b.jpg";
function Hero() {
  const navigate = useNavigate();
  const register = useRegister();
  return (
    <div className="bg-inherit">
      <div className="relative isolate">
        <div className="overflow-hidden">
          <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
            <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
              <div className="relative w-full lg:max-w-xl lg:shrink-0 xl:max-w-2xl">
                <h1 className="text-pretty text-5xl font-semibold tracking-tight text-white sm:text-7xl">
                  IGNITE 2025...
                </h1>
                <h1 className="text-pretty text-2xl font-semibold tracking-tight text-white sm:text-3xl">
                  The Aesthetic, Cosmetic and Regenerative Medicine Summit of
                  the Year!
                </h1>

                <p className="mt-8 text-pretty text-lg font-medium text-gray-200 sm:max-w-md sm:text-xl/8 lg:max-w-none">
                  WHEN: January 11, 2025
                </p>
                <a
                  href="https://hotelballast.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="text-pretty text-lg font-medium text-gray-200 sm:max-w-md sm:text-xl/8 lg:max-w-none">
                    WHERE: THE HOTEL BALLAST
                  </p>
                </a>
                <div className="text-white mt-8 text-xl">
                  Explore the latest advancements, technologies, and treatment
                  modalities shaping the future of cosmetic procedures and
                  regenerative therapies
                </div>
                <div className="bg-black opacity-80 w-full">
                  <p className="mt-8 opacity-100 text-white text-pretty text-md md:text-lg font-medium  sm:max-w-md lg:max-w-none">
                    A premier event that you do not want to miss. You will be
                    captivated by the wealth of knowledge and talent in which
                    you will encounter while attending this symposium. ISARM is
                    dedicated to delivering excellence across the board. Through
                    it’s immersive educational format, this summit aims to
                    elevate global standards of care, bringing the most
                    advanced, effective and evidence-based regenerative
                    treatments to cosmetic aesthetic medicine practices
                    worldwide.
                    <b>
                      {" "}
                      BRINGING TOGETHER LEADING EXPERTS, PRACTITIONERS and
                      INNOVATORS in the Fields of Aesthetics and Regenerative
                      Medicine,
                    </b>{" "}
                    we are certain that you will gain astounding knowledge,
                    advancing your skill set, in turn enabling you to catapult
                    your business to the next level. This summit offers a unique
                    platform for medical professionals to deepen their
                    knowledge, enhance their skill set, and exchange
                    groundbreaking insights into the latest advances in
                    non-surgical procedures aimed at antiaging, nonsurgical
                    procedures, rejuvenation and tissue repair.
                  </p>
                </div>
                <div className="mt-10 flex items-center gap-x-6">
                  <button
                    onClick={register.show}
                    className="rounded-md bg-[#2020d4] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#4242ed] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Register
                  </button>
                </div>
              </div>
              <div className="mt-14 flex justify-end sm:-mt-44 sm:justify-end sm:pl-20 lg:mt-0 lg:pl-0 mx-auto">
                <div className="ml-auto w-44 flex-none pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80 hidden xl:block">
                  <div className="relative">
                    <img
                      alt=""
                      src={img1}
                      className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                    />
                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                </div>
                <div className="mr-auto w-44 max-sm:w-1/2 flex-none sm:mr-0 sm:pt-52 lg:pt-36">
                  <div className="relative">
                    <img
                      alt=""
                      src={img2}
                      className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                    />
                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                  <div className="relative">
                    <img
                      alt=""
                      src={img3}
                      className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                    />
                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                </div>
                <div className="w-44 max-sm:w-1/2 flex-none pt-32 sm:pt-0">
                  <div className="relative">
                    <img
                      alt=""
                      src={img4}
                      className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                    />
                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                  <div className="relative">
                    <img
                      alt=""
                      src={img5}
                      className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                    />
                    <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
