function Content() {
  return (
    <div className="text-white max-w-3xl xl:max-w-7xl mx-auto px-6 text-xl xl:text-2xl pb-16">
      <div>
        Focusing on the application of regenerative medicine techniques such as
        stem cell therapy, platelet-rich plasma (PRP), exosome therapy, and
        bio-stimulative injectables, the summit provides comprehensive hands-on
        training, expert-led lectures, and case study discussions. Attendees
        will gain a deep understanding of the science behind these therapies and
        their transformative potential for treating a wide range of cosmetic
        concerns—from skin aging and hair restoration to post-surgical recovery
        and scar management.
      </div>
      <div className="py-4">
        The summit also fosters global collaboration, enabling doctors from
        different regions to connect, share best practices, and form
        partnerships that drive innovation within the field. Whether you're a
        seasoned professional looking to incorporate regenerative techniques
        into your practice or a newcomer eager to explore the future of
        aesthetic medicine, this event promises to be an invaluable educational
        experience and a catalyst for advancing the industry.
      </div>
      <div>
        Through its immersive educational format, the summit aims to elevate
        global standards of care and bring the most effective, evidence-based
        regenerative treatments to aesthetic medicine practices worldwide.
      </div>
    </div>
  );
}

export default Content;
