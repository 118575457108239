import img1 from "../resources/17525ab13bd27857520b6bcee7b299f1.jpg";
import cn from "../utils/cn";

function KeyTopics() {
  return (
    <div className="bg-inherit max-w-7xl mx-auto">
      <section aria-labelledby="features-heading" className="relative">
        <div className="aspect-h-2 aspect-w-3 overflow-hidden sm:aspect-w-5 lg:aspect-none lg:absolute lg:h-full lg:w-8/12">
          <img
            alt="close up girl"
            src={img1}
            className="-z-10 h-full w-full object-cover object-center lg:h-full lg:w-full lg:rounded-lg"
          />
        </div>
        <div className="mx-auto bottom-0 max-w-2xl px-4 pt-16 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:pt-32 mb-44">
          <div className="z-10 lg:bg-gray-200 lg:rounded-lg lg:shadow-md lg:p-10 p-4 lg:col-start-2 lg:translate-y-44 lg:opacity-95">
            <p className="mt-4 text-4xl font-bold tracking-tight text-white lg:text-gray-900 opacity-100">
              Key Topics
            </p>
            <p className="mt-4 text-gray-200 lg:text-gray-500 opacity-100">
              Everything you will learn at ISARM
            </p>

            <dl className="mt-10 grid grid-cols-1 gap-x-8 gap-y-10 text-sm sm:grid-cols-2 opacity-100">
              {key_topics.map((topic) => (
                <div key={topic.title}>
                  <dt className="font-medium text-white lg:text-gray-900">
                    {topic.title}
                  </dt>
                  <dd className="mt-2 text-gray-200 lg:text-gray-500">
                    {topic.content}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </section>
    </div>
  );
}

function KeyTopics1() {
  return (
    <div className="bg-white max-w-7xl">
      <section aria-labelledby="features-heading" className="relative">
        <div className="aspect-h-2 aspect-w-3 overflow-hidden sm:aspect-w-5 lg:aspect-none lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-16">
          <img
            alt="close up girl"
            src={img1}
            className="h-full w-full object-cover object-center lg:h-full lg:w-full"
          />
        </div>

        <div className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 sm:pb-32 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:pt-32">
          <div className="lg:col-start-2">
            <p className="mt-4 text-4xl font-bold tracking-tight text-gray-900">
              Key Topics
            </p>
            <p className="mt-4 text-gray-500">
              Everything you will learn at ISARM
            </p>

            <dl className="mt-10 grid-cols-1 gap-x-8 gap-y-10 text-sm sm:grid-cols-2">
              {key_topics.map((topic) => (
                <div key={topic.title}>
                  <dt className="font-medium text-gray-900">{topic.title}</dt>
                  <dd className="mt-2 text-gray-500">{topic.content}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </section>
    </div>
  );
}
function KeyTopics2() {
  return (
    <div>
      <KeyTopics2 />
      <div className="relative mx-auto max-w-7xl">
        <div className="absolute top-0 left-0 -z-10">
          <img src={img1} />
        </div>
        <div className="">
          {key_topics.map((topic, idx) => (
            <div key={idx}>
              <div className={`w-100 flex items-end justify-end `}>
                <Topic {...topic} index={idx} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
function Topic(props) {
  return (
    <div className="rounded-md p-2 m-2 py-6 my-6 max-w-lg shadow-md bg-rose-400 opacity-90 w-100 bg-gradient-to-br from-white via-rose-200 to-rose-500 hover:scale-105 transition-all">
      <div className="p-4 divide-y">
        <h2 className="text-3xl">{props.title}</h2>
        <div className="text-xl">{props.content}</div>
      </div>
    </div>
  );
}

const key_topics = [
  {
    title: "Innovations in Non-Surgical Aesthetics",
    content:
      "Keen Focus on cutting-edge techniques in injectables, lasers, and skin rejuvenation",
  },
  {
    title: "Patient Safety and Ethics",
    content:
      "Emphasizing the importance of ethical practices and patient safety in cosmetic procedures.",
  },
  {
    title: "Regenerative Medicine",
    content:
      "Exploring the role of stem cells and PRP in aesthetic treatments.",
  },
  {
    title: "Business of Aesthetics",
    content:
      "Strategies for building and managing a successful practice in the competitive aesthetic market.",
  },
  {
    title: "Advanced Non Surgical Facial Augmentation Techniques",
    content:
      "Discussions on advancements in non-surgical procedures, including facelifts, rhinoplasty, and body contouring.",
  },
];
export default KeyTopics;
