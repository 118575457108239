import * as React from "react";
import { Label } from "../components/label";
import { Input } from "../components/input";
import { LabelInputContainer } from "../components/label_input_container";
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";

function PaymentInformation(props) {
  return (
    <div>
      <div className="hidden">
        --All Medical Professionals (MD, RN, DO, etc) : $1599 EARLY BIRD VIP
        PRICE: $999 Estheticians, Permanent Make up Artist, Medical Assistants,
        Practice Management Team: $1199 EARLY BIRD VIP PRICE: $699
      </div>
      <PaymentForm
        applicationId="sq0idp-WJyLpYT7em3QyrRrOpaR3Q"
        cardTokenizeResponseReceived={props.handleTokenResponse}
        locationId="XXXXXXXXXX"
      >
        <CreditCard />
      </PaymentForm>
    </div>
  );
}
export default PaymentInformation;
