import cn from "../utils/cn";
import { StarIcon } from "@heroicons/react/20/solid";

import image from "../resources/5f9567f533ca1b8d38ff2cbf86d848cb.jpg";
import image2 from "../resources/9e5a0c6787b6bc6fbac3b60a63d288f4.jpg";
import image3 from "../resources/db4bdaf4b49af391650b7df743ef935a.jpg";
import image4 from "../resources/dc58ffdd3f90cf51f82057ed9d19370b.jpg";
function Format() {
  /*
   * doing something with like alternating image
   * and text and maybe offset them cool and such
   * */
  return (
    <div className="max-w-7xl mx-auto md:px-6 lg:px-8">
      <div className="md:bg-gray-200 sm:bg-inherit rounded-xl my-16 shadow-md">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-6 py-8 lg:max-w-7xl lg:px-8">
          <div className=" space-y-16">
            <div className="flex gap-12">
              <ul className="mt-8 space-y-8 text-gray-600 md:w-3/4 w-full">
                {features.map((feature, idx) => (
                  <li
                    key={`format-list-${idx}`}
                    className="flex gap-x-3 border shadow p-6 rounded-lg hover:scale-105 transition bg-gray-200"
                  >
                    <StarIcon
                      aria-hidden="true"
                      className="mt-1 h-5 w-5 flex-none text-indigo-600"
                    />
                    <span>
                      <strong className="font-semibold text-gray-900">
                        {feature.title}{" "}
                      </strong>
                      {feature.description}
                    </span>
                  </li>
                ))}
              </ul>
              <div className="hidden md:block aspect-h-2 aspect-w-5 rounded-lg bg-gray-100">
                <img
                  alt="hand"
                  src={image}
                  className="object-cover object-center rounded-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const features = [
  {
    title: "Keynote Speakers",
    description: "Renowned experts share insights and trends in the industry.",
    imageSrc: image,
  },
  {
    title: "Panel/Platform Discussions",
    description:
      "Interactive sessions addressing controversial topics and emerging challenges.",
    imageSrc: image2,
  },
  {
    title: "Clinical Workshops",
    description: "Hands-on training in the latest techniques and technologies.",
    imageSrc: image3,
  },
  {
    title: "Networking Events",
    description: "Opportunities for attendees to connect and collaborate.",
    imageSrc: image4,
  },
];
export default Format;
