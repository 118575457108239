import { Label } from "../components/label";
import { Input } from "../components/input";
import { LabelInputContainer } from "../components/label_input_container";
function MedicalInformation(props) {
  return (
    <>
      <div className="lg:flex lg:gap-2 mb-4">
        <LabelInputContainer>
          <Label htmlFor="medicalLicenseNumber">
            Your Medical License Number
          </Label>
          <Input
            name="medical_license_number"
            onChange={props.handleChange}
            value={props.state.medical_license_number}
            id="medicalLicenseNumber"
            type="text"
          />
        </LabelInputContainer>
        <LabelInputContainer>
          <Label htmlFor="medicalLicenseExp">
            Medical License Number Expiry Date
          </Label>
          <Input
            name="medical_exp"
            onChange={props.handleChange}
            value={props.state.medical_exp}
            id="medicalLicenseExp"
            type="date"
          />
        </LabelInputContainer>
      </div>
      <LabelInputContainer className="mb-4">
        <Label htmlFor="medicalSpecialty">Medical Specialty</Label>
        <Input
          name="medical_specialty"
          onChange={props.handleChange}
          value={props.state.medical_specialty}
          id="medicalSpecialty"
          type="text"
        />
      </LabelInputContainer>
    </>
  );
}
export default MedicalInformation;
