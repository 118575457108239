import { TypewriterEffectSmooth } from "../components/typewriter_effect";
import { PlaceholdersAndVanishInput } from "../components/placeholders_and_vanish_input";
import * as React from "react";
import { joinMailingList } from "../api";
import useNotification from "../notification/use_notification";
function MailList() {
  const [state, setState] = React.useState("");
  const notify = useNotification();
  const handleChange = (e) => {
    setState(e.target.value);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    joinMailingList(state);
    notify.success("Success!", "Thank you for joining our mailing list!");
  };
  return (
    <div className="h-96 relative w-full overflow-hidden bg-inherit flex flex-col items-center justify-center rounded-lg">
      <div className="absolute inset-0 w-full h-full bg-inherit z-10 [mask-image:radial-gradient(transparent,white)] pointer-events-none" />

      <div className=" flex flex-col items-center justify-center h-[40rem]  ">
        <h3 className="text-white text-xl ">
          <b>Transform . Evolve . Grow</b>
        </h3>
        <TypewriterEffectSmooth words={words} />
        <div className="mb-6 z-10 ">
          <PlaceholdersAndVanishInput
            placeholders={placeholders}
            onChange={handleChange}
            value={state}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </div>
  );
}
const placeholders = ["Enter Email here", "example@domain.com"];
const words = [
  {
    text: "Join the ISARM vip network for updates.",
  },
];
export default MailList;
